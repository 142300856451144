import React, { useState, useEffect, useCallback } from 'react';
import { usePortals } from 'react-portal-hook';
import { SvgIcon, TooltipDisclaimer } from '@ui-elements';
import { getVehiclePrice } from 'common/services/pricing-service';
import {
  LocationSelectedEvent,
  PriceInfo,
  RepaymentInfo,
  Suburb,
} from 'common/models';
import { ModelDetailPricing } from 'common/components';
import { replaceRegex } from 'common/utils';
import { PriceDetails } from '../price-details';
import { EnterPostCode } from '../enter-postcode';
import { ShowroomModel } from '../../models';
import './index.scss';
import {
  getRepayments,
  getFinanceFormInput,
  openFinanceModal,
  closeFinanceModal,
  getFinanceFormInputPersonalised,
} from '~/common/services/finance-service';
import { usePostcode } from '~/common/hooks/use-postcode';
import { GenericModal } from '~/common/components/generic-modal';
import { LocationDetails } from '../location-details';

interface Props {
  size: 'half' | 'full';
  model: ShowroomModel;
}

export const VehicleCard = React.memo<Props>(({ size, model }) => {
  const portalManager = usePortals();
  const [suburb, setSuburb] = useState<Suburb | null>(null);
  const [priceInfo, setPriceInfo] = useState<PriceInfo | null>(null);
  const [repaymentInfo, setRepaymentInfo] = useState<RepaymentInfo | null>(
    null
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const { postcode } = usePostcode();

  const locationSelected = useCallback(
    async (e: LocationSelectedEvent) => {
      if (e.detail) {
        setSuburb(e.detail.suburb);

        try {
          setError(false);
          setLoading(true);
          const price = await getVehiclePrice(
            model.id,
            e.detail.suburb.postcode
          );
          setPriceInfo(price);
          const repayment = await getRepayments(
            model.pimIdentifier,
            getFinanceFormInput(null),
            postcode
          );
          setRepaymentInfo(repayment);
        } catch (ex) {
          setError(true);
          console.error(
            'An error occurred loading RDP for your selected postcode..',
            model.id
          );
        } finally {
          setLoading(false);
        }
      }
    },
    [model.id]
  );

  const onFinanceFormInput = useCallback(
    async (e: any) => {
      if (e.detail) {
        try {
          setError(false);
          setLoading(true);
          setRepaymentInfo(
            await getRepayments(
              model.pimIdentifier,
              getFinanceFormInput(e),
              postcode
            )
          );
        } catch {
          setError(true);
        } finally {
          setLoading(false);
        }
      }
    },
    [model.id]
  );

  const handleShowPostCode = () => {
    window.dispatchEvent(new CustomEvent('selectLocation', { detail: {} }));
  };

  const handleShowFinanceModal = () => {
    openFinanceModal(model.pimIdentifier, postcode);
  };

  useEffect(() => {
    window.addEventListener('locationSelected', locationSelected);
    return () => {
      window.removeEventListener('locationSelected', () => locationSelected);
    };
  }, [locationSelected]);

  useEffect(() => {
    window.addEventListener('continue', onFinanceFormInput);
    return () => {
      window.removeEventListener('continue', () => onFinanceFormInput);
    };
  }, [onFinanceFormInput]);

  useEffect(() => {
    window.addEventListener('closeModal', () => {
      closeFinanceModal();
    });
  });

  const handleShowPrice = () => {
    portalManager.open((portal) => (
      <ModelDetailPricing
        modelName={model.model}
        mlp={priceInfo?.mlp as number}
        rdp={priceInfo?.rdp as number}
        closeModal={portal.close}
      />
    ));
  };

  const handleShowFinanceDisclaimer = (element: any, title: string) => {
    portalManager.open((portal) => (
      <GenericModal title={title} element={element} closeModal={portal.close} />
    ));
  };

  const vehicleNameAndPostCode = () => (
    <div>
      <div className="vehicleNameAndPostCode">
        <img loading="lazy" src={model.modelImageName} alt={model.model} />
        {model.displayModelPrice &&
          (suburb === null || priceInfo === null || repaymentInfo === null ? (
            <EnterPostCode
              error={isError}
              loading={isLoading}
              showPostCode={handleShowPostCode}
            />
          ) : (
            <LocationDetails
              suburb={suburb}
              price={priceInfo?.rdp}
              showPostCode={handleShowPostCode}
              showPriceDetails={handleShowPrice}
            />
          ))}
      </div>
      {suburb !== null &&
        priceInfo !== null &&
        repaymentInfo !== null &&
        model.displayModelPrice && (
          <PriceDetails
            price={priceInfo?.rdp}
            showPostCode={handleShowPostCode}
            showPriceDetails={handleShowPrice}
            loading={isLoading}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            repaymentInfo={repaymentInfo!}
            showFinanceModal={handleShowFinanceModal}
            isFinanceFormInputPersonalised={getFinanceFormInputPersonalised()}
            showFinanceDisclaimer={handleShowFinanceDisclaimer}
            suburb={suburb}
          />
        )}
    </div>
  );

  const vehicleDescription = () => (
    <div className="vehicleDescription">
      <p>{model.modelText}</p>
    </div>
  );

  const vehicleFeatures = () => (
    <div className="vehicleFeatures">
      <ul>
        {model.features.map((feature, index) => (
          <li key={index}>
            <TooltipDisclaimer disclaimer={feature.featureDisclaimer}>
              {feature.featureName}
            </TooltipDisclaimer>
          </li>
        ))}
      </ul>
    </div>
  );

  const vehicleCTAs = () => (
    <>
      <div className="vehicleCTAs">
        {model.ctA1Text && model.ctA1Link && (
          <a
            id={`cta1-${replaceRegex(model.model)}`}
            href={model.ctA1Link}
            className="cta-btn"
          >
            {model.ctA1Text}
          </a>
        )}

        {model.ctA2Text && model.ctA2Link && (
          <a
            id={`cta2-${replaceRegex(model.model)}`}
            href={model.ctA2Link}
            className="cta-btn"
          >
            {model.ctA2Text}
          </a>
        )}
      </div>
      <div className="vehicleFooterCTAs">
        <a
          id={`byo-${replaceRegex(model.model)}`}
          href="/configure"
          className="cta__btn"
        >
          <SvgIcon type="chevronRight" size="12px" color="#0042a6" />
          <span>Build your own</span>
        </a>
        {!model.hideInBrochureDownload && (
          <a
            id={`dlb-${replaceRegex(model.model)}`}
            href={`/brochure-download?model=${model.model}`}
            className="cta__btn"
          >
            <SvgIcon type="chevronRight" size="12px" color="#0042a6" />
            <span>Download brochure</span>
          </a>
        )}
        {!model.hideInTestDrive && (
          <a
            id={`rtd-${replaceRegex(model.model)}`}
            href={`/book-test-drive?model=${model.model}`}
            className="cta__btn"
          >
            <SvgIcon type="chevronRight" size="12px" color="#0042a6" />
            <span>Request a test drive</span>
          </a>
        )}
      </div>
    </>
  );

  return (
    <div
      id={replaceRegex(model.model)}
      className={`vehicleCard vehicleCard__${size}`}
    >
      <div className="vehicleCard__modelImg">
        <img loading="lazy" src={model.modelImage} alt={model.model} />
      </div>

      <div className="vehicleCard__wrapper">
        {[
          vehicleNameAndPostCode(),
          vehicleDescription(),
          vehicleFeatures(),
          vehicleCTAs(),
        ]}
      </div>
    </div>
  );
});
