import React from 'react';
import { Waypoint } from 'react-waypoint';
import { VehicleCard } from '../vehicle-card';
import { ShowroomCategory } from '../../models';
import './index.scss';

interface Props {
  category: ShowroomCategory;
  backgroundColor: string;
  onViewEnter: (url: string) => void;
}

export const VehicleCategory = React.forwardRef<HTMLDivElement, Props>(
  ({ category, backgroundColor, onViewEnter }, ref) => {
    const containerSize = (index: number) => {
      const curIndex = index + 1;
      const isLastIndex = category.showroomModels.length === curIndex;
      return isLastIndex && curIndex % 2 !== 0 ? 'full' : 'half';
    };

    const handleViewEnter = () => {
      onViewEnter(category.url);
    };

    return (
      <Waypoint topOffset={200} bottomOffset={500} onEnter={handleViewEnter}>
        <div
          ref={ref}
          id={category.id}
          key={category.id}
          className="srCategory"
          style={{ backgroundColor }}
        >
          <div className="container">
            <h2
              className="srCategory__title"
              dangerouslySetInnerHTML={{ __html: category.categoryTitle }}
            />
            <div className="srCategory__description">
              <p className="srCategory__descriptionText">
                {category.categoryDescription}
              </p>
            </div>

            {category.learnMore.visible && (
              <div className="srCategory__learnMore">
                <a
                  id={category.id}
                  href={category.learnMore.link}
                  target={category.learnMore.target || 'self'}
                  className="srCategory__ctaBtn"
                >
                  {category.learnMore.text}
                </a>
              </div>
            )}

            <div className="srCategory__grid">
              {category.showroomModels.map((vehicle, index) => {
                return (
                  <VehicleCard
                    key={vehicle.id}
                    size={containerSize(index)}
                    model={vehicle}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Waypoint>
    );
  }
);
