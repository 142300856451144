import React from 'react';
import { ShowroomCategory } from './models';
import { PageLayout, PageHero } from '~/common/components';
import { VehicleCategory } from './components/vehicle-category';
import { NavCategory, NavCategoryComponent } from './components/nav-category';
import './styles.scss';
import { RecoilRoot } from 'recoil';

interface Props {
  cdnBasePath: string;
  heroBackground: string;
  showroomCategories: ShowroomCategory[];
}

export const Showroom: React.FC<Props> = ({
  cdnBasePath,
  heroBackground,
  showroomCategories,
}) => {
  const navRefs = React.useRef<NavCategoryComponent | null>(null);
  const vehicleRefs = React.useRef<(HTMLDivElement | null)[]>([]);
  const sortedCategories = showroomCategories.sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  let utmStr: string;

  React.useEffect(() => {
    if (window.location.href.indexOf('?') > 1) {
      utmStr = window.location.search.substring(1);
    }
  }, [sortedCategories]);

  const updateWindowHistory = (url: string) => {
    let newUrlStr = url;
    if (utmStr !== undefined) {
      if (utmStr !== null && utmStr.includes('utm')) {
        newUrlStr = `${url}?${utmStr.toLowerCase()}`;
      }
    }
    window.history.replaceState(null, window.document.title, newUrlStr);
  };

  // navRefs.current?.setActiveLink()

  const handleScrollTo = (url: string) => {
    const index = sortedCategories.findIndex((x) => x.url === url);
    const divRef = vehicleRefs.current[index];

    if (divRef) {
      window.scrollTo({
        behavior: 'smooth',
        top: divRef.offsetTop - 85,
      });

      updateWindowHistory(url);
    }
  };

  const handleViewVehicleViewEnter = (url: string) => {
    updateWindowHistory(url);
    navRefs.current?.setActiveLink(url);
  };

  return (
    <PageLayout>
      <PageHero src={heroBackground} />

      <NavCategory
        ref={navRefs}
        cdnBasePath={cdnBasePath}
        onScrollTo={handleScrollTo}
        categories={sortedCategories.map((category) => ({
          id: category.id,
          url: category.url,
          label: category.name,
        }))}
      />

      <div className="page-title">
        <h1>Introducing the Subaru range</h1>
      </div>

      {sortedCategories.map((showroomCategory, index) => (
        <VehicleCategory
          key={showroomCategory.id}
          category={showroomCategory}
          ref={(ref) => vehicleRefs.current.push(ref)}
          backgroundColor={index % 2 === 0 ? '#EDEDED' : '#E5E5E5'}
          onViewEnter={handleViewVehicleViewEnter}
        />
      ))}
    </PageLayout>
  );
};
