import React from 'react';
import { Suburb } from '~/common/models';

export interface Props {
  showPostCode: () => void;
  showPriceDetails: () => void;
  suburb: Suburb;
  price: number;
}

export const LocationDetails = React.memo<Props>((props) => {
  const { suburb } = props;

  return (
    <div className="rdp-postcode">
      <div className="postcode-location">
        <p>
          <span>YOUR LOCATION: </span>
          <span>{`${suburb.name}, ${suburb.postcode} ${suburb.state}`}</span>
          <span onClick={props.showPostCode}>edit</span>
        </p>
      </div>
    </div>
  );
});
