import React from 'react';
import { Link, scroller } from 'react-scroll';
import { replaceRegex } from '~/common/utils';
import './index.scss';

type NavCategory = {
  id: string;
  url: string;
  label: string;
};

interface Props {
  cdnBasePath: string;
  categories: NavCategory[];
  onScrollTo: (url: string) => void;
}

export interface NavCategoryComponent {
  setActiveLink: (url: string) => void;
}

export const NavCategory = React.forwardRef<NavCategoryComponent, Props>(
  ({ cdnBasePath, onScrollTo, categories }, forwardRef) => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [isSticky, setSticky] = React.useState<boolean>(false);
    const [activeURL, setActiveURL] = React.useState<string | null>(null);

    const handleScroll = React.useCallback(() => {
      if (ref.current) {
        if (window.scrollY < 300) {
          setSticky(false);
        } else {
          setSticky(ref.current.getBoundingClientRect().top <= 85);
        }
      }
    }, []);

    React.useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', () => handleScroll);
      };
    }, [handleScroll]);

    React.useEffect(() => {
      setActiveURL(window.location.pathname);

      const windowPath = replaceRegex(window.location.pathname, /\//g, '');
      const category = categories.find(
        (x) => replaceRegex(x.url, /\//g, '') === windowPath
      );

      if (!category) return;

      scroller.scrollTo(`${category.id}`, {
        smooth: true,
        delay: 300,
        offset: -150,
      });
    }, [categories]);

    React.useImperativeHandle(
      forwardRef,
      () => ({
        setActiveLink(url: string) {
          setActiveURL(url);
        },
      }),
      []
    );

    const handleScrollSetActive = (url: string) => {
      setActiveURL(url);
      onScrollTo(url);
    };

    return (
      <div
        ref={ref}
        id="category-nav"
        className={`category-nav ${isSticky ? 'category-nav--sticky' : ''}`}
      >
        <div className="container">
          <ul>
            {categories.map((category, index) => {
              const name = replaceRegex(category.label);
              const isActive = category.url === activeURL;
              const navIcon = isActive
                ? `${cdnBasePath}/dist/assets/svg-inline/${name}-white.svg`
                : `${cdnBasePath}/dist/assets/svg-inline/${name}-blue.svg`;

              return (
                <li key={index}>
                  <Link
                    id={name}
                    to={`${category.id}`}
                    className={`category-nav__link ${
                      isActive && 'category-nav__link--active'
                    }`}
                    onSetActive={() => handleScrollSetActive(category.url)}
                    href={category.url}
                    offset={-150}
                    spy={false}
                    smooth
                  >
                    <img src={navIcon} alt={category.label} />
                    <span>{category.label}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
);
