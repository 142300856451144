import React from 'react';
import { SvgIcon } from '@ui-elements';
import './index.scss';

interface Props {
  error: boolean;
  loading?: boolean;
  showPostCode: () => void;
}

export const EnterPostCode = React.memo<Props>(
  ({ showPostCode, loading, error }) => {
    const loadingClass = loading ? 'inlineLoader--loading' : '';

    return (
      <div className={`postcode inlineLoader ${loadingClass}`}>
        <span className="inlineLoader__loader" />

        {!error ? (
          <>
            <SvgIcon type="location" size={1.5} color="#1637a0" />
            <p>
              Enter your <span onClick={showPostCode}>postcode</span> for the
              RDP in your area
            </p>
          </>
        ) : (
          <p className="error">
            An error occurred loading RDP from your selected post code.{' '}
            <span onClick={showPostCode}>Try again.</span>
          </p>
        )}
      </div>
    );
  }
);
