import React from 'react';
import { formatMoney, formatNumber } from 'accounting';
import { RepaymentInfo, Suburb } from '~/common/models';
import './index.scss';
import { SvgIcon } from '~/common/components/ui-elements/svg-icon';
import {
  RepaymentsDisclaimer1,
  RepaymentsDisclaimer2,
  RepaymentsDisclaimer4,
  RepaymentsDisclaimerForComparisonRate,
} from './RepaymentDisclaimers';

export interface Props {
  showPriceDetails: () => void;
  showPostCode: () => void;
  showFinanceModal: () => void;
  showFinanceDisclaimer: (element: any, title: string) => void;
  loading: boolean;
  price: number | undefined;
  repaymentInfo: RepaymentInfo;
  isFinanceFormInputPersonalised: boolean;
  variantId?: string;
  suburb: Suburb;
}

export const PriceDetails = React.memo<Props>((props) => {
  const { loading, repaymentInfo, suburb, isFinanceFormInputPersonalised } =
    props;
  const loadingClass = loading ? 'inlineLoader--loading' : '';

  return (
    <div className={`showroom__finance inlineLoader ${loadingClass}`}>
      <span className="inlineLoader__loader" />
      <div className="">
        <div className="showroom__finance_rdp show-on-mobile">
          <div className="showroom__finance_payment-value-from">From</div>
          <div
            className="showroom__finance_payment-value-line-one"
            onClick={props.showPriceDetails}
          >
            <span>
              {formatMoney(Number(props.price ? props.price : 0), {
                symbol: '$',
                precision: 0,
              })}{' '}
            </span>
          </div>
          <div
            className="showroom__finance_payment-value-detailed-pricing"
            onClick={props.showPriceDetails}
          >
            Recommended Driveaway Price
            <i className="icon icon--info" />
          </div>
        </div>
        <hr />
        <div className="showroom__finance_value-flex-container">
          <div className="showroom__finance_rdp show-on-desktop">
            <div className="showroom__finance_payment-value-from">From</div>
            <div
              className="showroom__finance_payment-value-line-one"
              onClick={props.showPriceDetails}
            >
              <span>
                {formatMoney(Number(props.price ? props.price : 0), {
                  symbol: '$',
                  precision: 0,
                })}{' '}
              </span>
            </div>
            <div
              className="showroom__finance_payment-value-detailed-pricing"
              onClick={props.showPriceDetails}
            >
              Recommended Driveaway Price
              <i className="icon icon--info" />
            </div>
          </div>
          {!(
            repaymentInfo.isGfvNotAvailableError ||
            repaymentInfo.isDepositTooHighError ||
            repaymentInfo.isGeneralError
          ) && (
            <div className="showroom__finance_payment-value">
              <div className="showroom__finance_payment-value-from">From</div>
              <div
                className="showroom__finance_payment-value-line-one"
                onClick={() => {
                  props.showFinanceDisclaimer(
                    RepaymentsDisclaimer2(
                      formatMoney(Number(props.price ? props.price : 0), {
                        symbol: '$',
                        precision: 0,
                      }),
                      repaymentInfo.term,
                      repaymentInfo.interestRate,
                      formatMoney(repaymentInfo.deposit, {
                        symbol: '$',
                        precision: 2,
                      }),
                      repaymentInfo.paymentFrequency,
                      repaymentInfo.monthlyRepayment,
                      repaymentInfo.financeProductId === 'FIN_PROD_AUS_SUB_GFV',
                      formatNumber(repaymentInfo.mileage),
                      formatMoney(repaymentInfo.residualValue, {
                        symbol: '$',
                        precision: 2,
                      })
                    ),
                    ''
                  );
                }}
              >
                {formatMoney(repaymentInfo.paymentValue, {
                  symbol: '$',
                  precision: 0,
                })}{' '}
                p/{repaymentInfo.paymentFrequencyString}
                <sup>2</sup>
              </div>
              {repaymentInfo.residualValue > 0 && (
                <div
                  className="showroom__finance_payment-value-line-two"
                  onClick={() => {
                    props.showFinanceDisclaimer(RepaymentsDisclaimer1(), '');
                  }}
                >
                  with Subaru GFV<sup>1</sup>
                </div>
              )}
            </div>
          )}
          {!(
            repaymentInfo.isGfvNotAvailableError ||
            repaymentInfo.isDepositTooHighError ||
            repaymentInfo.isGeneralError
          ) ? (
            <div className="showroom__finance_comparison-rate">
              <div className="showroom__finance_payment-value-from" />
              <div
                className="showroom__finance_comparison-rate-line-one"
                onClick={() => {
                  props.showFinanceDisclaimer(
                    RepaymentsDisclaimer4(isFinanceFormInputPersonalised),
                    ''
                  );
                }}
              >
                {repaymentInfo.comparisonRate}% p.a.<sup>4</sup>
              </div>
              <div
                className="showroom__finance_comparison-rate-line-two"
                onClick={() => {
                  props.showFinanceDisclaimer(
                    RepaymentsDisclaimerForComparisonRate(),
                    ''
                  );
                }}
              >
                Comparison rate
                <i className="icon icon--info" />
              </div>
            </div>
          ) : (
            <div className="showroom__finance_comparison-rate">
              {repaymentInfo.errorDescription}
            </div>
          )}
        </div>
        <hr />
        {
          <div className="showroom__finance_disclaimer">
            Recommended Driveaway Price (RDP)&nbsp;
            {`${
              suburb ? `shown based on ${suburb.name}, ${suburb.postcode}` : ''
            }`}
            . RDP may change based on location.&nbsp;
            <a
              href="#"
              className="showroom__finance_disclaimer_link"
              onClick={props.showPostCode}
            >
              Enter your postcode&nbsp;
            </a>
            for the RDP in your area.
          </div>
        }
        {!(
          repaymentInfo.isGfvNotAvailableError ||
          repaymentInfo.isDepositTooHighError ||
          repaymentInfo.isGeneralError
        ) ? (
          <div className="showroom__finance_disclaimer">
            Estimated repayments based on a deposit of{' '}
            {formatMoney(repaymentInfo.deposit, {
              symbol: '$',
              precision: 0,
            })}{' '}
            over a {repaymentInfo.term} month term
            {repaymentInfo.financeProductId === 'FIN_PROD_AUS_SUB_GFV' &&
            repaymentInfo.residualValue > 1 ? (
              <span>
                {' '}
                with a Guaranteed Future Value of{' '}
                {formatMoney(repaymentInfo.residualValue, {
                  symbol: '$',
                  precision: 0,
                })}{' '}
                with an annual km allowance of{' '}
                {formatNumber(repaymentInfo.mileage)}.{' '}
              </span>
            ) : (
              '. '
            )}
            <div className="showroom__finance_disclaimer-settings">
              <a onClick={props.showFinanceModal}>
                <SvgIcon type="pencil" size={0.8} />{' '}
                {props.isFinanceFormInputPersonalised
                  ? 'Edit finance settings'
                  : 'Personalise finance settings'}
              </a>
            </div>
          </div>
        ) : (
          <div className="showroom__finance_disclaimer">
            <div className="showroom__finance_disclaimer-settings">
              <a onClick={props.showFinanceModal}>
                <SvgIcon type="pencil" size={0.8} />{' '}
                {props.isFinanceFormInputPersonalised
                  ? 'Edit finance settings'
                  : 'Personalise finance settings'}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
